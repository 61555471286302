{
  "accpDescription": "Monitorice los activos clave y haga tareas administrativas importantes para Adobe Campaign",
  "acsDescription": "Administre campañas de canales cruzados y mejore la personalización en todo su panorama de medios",
  "adcloudDescription": "Pronostique la mejor combinación de anuncios según el presupuesto y automatice la ejecución de su plan de medios",
  "adminDescription": "Vea a todos los usuarios y los detalles de Experience Cloud, incluido el acceso, la función y los últimos accesos",
  "adminconsoleDescription": "Administre los usuarios, la identidad y las aplicaciones de Experience Cloud.",
  "analyticsDescription": "Recopile, organice, analice e informe sobre todo lo que hacen sus clientes",
  "audiencemanagerDescription": "Administre todos los datos de sus clientes, cree perfiles de público únicos y diríjase a los usuarios previstos",
  "bizibleDescription": "Atribuya los ingresos a sus canales de marketing B2B, a los activos de contenido y a las campañas para demostrar y mejorar el retorno de la inversión (ROI).",
  "cjm-homeDescription": "Organizar recorridos de clientes, diseñar y enviar mensajes, y obtener información",
  "cloudManagerUiDescription": "Genere, pruebe e implemente aplicaciones de Adobe, y administre sus implementaciones de código personalizado",
  "ddamDescription": "Descubra, administre y comparta fácilmente activos digitales con todos los colaboradores",
  "experienceManagerUIDescription": "Ofrezca experiencias personalizadas y basadas en contenido combinando la administración de activos digitales con un poderoso sistema de administración de contenido",
  "experiencePlatformUIDescription": "Agregue todos sus datos empresariales y de experiencias en aplicaciones de Adobe",
  "fireflyDescription": "Acceda a sus aplicaciones nativas de la nube personalizadas creadas para ampliar la funcionalidad de los productos Experience Platform y Experience Cloud",
  "implementDescription": "Implemente y administre etiquetas para productos y servicios de Experience Cloud y soluciones de terceros",
  "journeysDescription": "Planifique, entregue y analice recorridos con datos de sistemas de terceros y Experience Platform",
  "launchDataCollectionDescription": "Enviar datos del flujo de navegación a Adobe Experience Cloud y otros destinos web, móviles, de edge y de servidores",
  "launchDescription": "Implemente y administre etiquetas para productos y servicios de Experience Cloud y soluciones de terceros",
  "marketoDescription": "Automatice y mida la participación de marketing de todos los canales para ofrecer las experiencias B2B más oportunas y efectivas",
  "placesDescription": "Habilite las aplicaciones móviles con reconocimiento de ubicación para tomar medidas a través de características y servicios de localización geográfica",
  "platformAnalyticsDescription": "Analice los recorridos de los clientes a través de los canales con los datos de Experience Platform.",
  "primetimeDescription": "Cree experiencias atractivas y personalizadas de cine y TV a través de una plataforma de TV multipantalla",
  "privacyuiDescription": "Envíe, monitorice y responda a las solicitudes de acceso y eliminación de datos de los clientes según las normas de privacidad vigentes",
  "softwareDistributionDescription": "Busque, descubra y descargue fácilmente paquetes para Adobe Experience Manager y Adobe Campaign",
  "systemStatusDescription": "Monitorice la interrupción de servicios, la alteración y los eventos de mantenimiento de los productos en la nube de Adobe",
  "targetDescription": "Utilice la automatización de la experimentación, las pruebas y el aprendizaje automático para ofrecer el mejor contenido a cada visitante"
}
