{
  "accpDescription": "Surveillez les ressources clés et effectuez des tâches administratives importantes pour Adobe Campaign.",
  "acsDescription": "Gérez les campagnes cross-canal et améliorez la personnalisation dans l’ensemble de votre paysage médiatique.",
  "adcloudDescription": "Prévoyez le meilleur mix de publicités en fonction du budget et automatisez l’exécution de votre plan médias.",
  "adminDescription": "Affichez tous les utilisateurs Experience Cloud et les informations associées y compris l’accès, le rôle et la date/heure du dernier accès.",
  "adminconsoleDescription": "Gérez les utilisateurs, les identités et les applications Experience Cloud.",
  "analyticsDescription": "Collectez, organisez, analysez et établissez des rapports sur toutes les activités de vos clients.",
  "audiencemanagerDescription": "Gérez toutes les données de vos clients, créez des profils d’audience uniques et ciblez précisément vos utilisateurs.",
  "bizibleDescription": "Attribuez un chiffre d’affaires à vos canaux marketing B2B, ressources de contenu et campagnes pour obtenir un retour sur investissement et l’améliorer.",
  "cjm-homeDescription": "Orchestrez des parcours client, concevez et diffusez des messages et recevez des informations.",
  "cloudManagerUiDescription": "Créez, testez et déployez des applications Adobe, et gérez vos déploiements de codes personnalisés.",
  "ddamDescription": "Découvrez, gérez et partagez facilement des ressources numériques avec tous vos collaborateurs.",
  "experienceManagerUIDescription": "Proposez des expériences personnalisées basées sur le contenu en associant la gestion des ressources numériques à un puissant système de gestion de contenu.",
  "experiencePlatformUIDescription": "Agrégez toutes vos données d’expérience sur les applications Adobe et les données d’entreprise.",
  "fireflyDescription": "Accédez à vos applications natives cloud personnalisées conçues pour étendre la fonctionnalité des produits Experience Platform et Experience Cloud.",
  "implementDescription": "Déployez et gérez les balises pour les produits et services Experience Cloud et les solutions tierces.",
  "journeysDescription": "Planifiez, diffusez et analysez les parcours à l’aide de données provenant de systèmes tiers et d’Experience Platform.",
  "launchDataCollectionDescription": "Envoyez des données de parcours de navigation vers Adobe Experience Cloud et d’autres destinations par le biais du web, des appareils mobiles, de Edge et du serveur.",
  "launchDescription": "Déployez et gérez les balises pour les produits et services Experience Cloud et les solutions tierces.",
  "marketoDescription": "Automatisez et mesurez l’engagement marketing sur tous les canaux pour offrir des expériences B2B dynamiques et efficaces.",
  "placesDescription": "Intégrez la connaissance des emplacements aux applications mobiles pour agir en vous appuyant sur des fonctionnalités et des services de géolocalisation.",
  "platformAnalyticsDescription": "Analysez les parcours clients sur tous les canaux à l’aide des données Experience Platform.",
  "primetimeDescription": "Créez des expériences télévisuelles et cinématiques attrayantes et personnalisées à l’aide d’une plateforme TV multi-écran.",
  "privacyuiDescription": "Soumettez, surveillez et répondez aux demandes d’accès et de suppression des données des clients dans le cadre des règlementations sur la vie privée concernées.",
  "softwareDistributionDescription": "Recherchez, trouvez et téléchargez facilement des packages pour Adobe Experience Manager et Adobe Campaign.",
  "systemStatusDescription": "Surveillez les pannes du service, les perturbations et les activités de maintenance pour les produits cloud Adobe.",
  "targetDescription": "Utilisez l’automatisation de l’expérimentation, des tests et du machine learning pour offrir le meilleur contenu à chaque visiteur."
}
