{
  "editprofile": {
    "dev": "https://experience-qa.adobe.com/#/<tenant>/preferences",
    "qa": "https://experience-qa.adobe.com/#/<tenant>/preferences",
    "stage": "https://experience-stage.adobe.com/#/<tenant>/preferences",
    "prod": "https://experience.adobe.com/#/<tenant>/preferences"
  },
  "logout": {
    "dev": "https://ims-na1-qa2.adobelogin.com/ims/logout/v1?client_id=MarketingCloud11_dev&redirect_uri=https://<tenant>.experience-qadev.corp.adobe.com/exc-content/login.html",
    "qa": "https://experience-qa.corp.adobe.com/exc-session/logout?redirect_uri=https://<tenant>.experience-qa.corp.adobe.com/exc-content/login.html",
    "stage": "https://experience-qa.corp.adobe.com/exc-session/logout?redirect_uri=https://<tenant>.experience-qa.corp.adobe.com/exc-content/login.html",
    "prod": "https://experiencecloud.adobe.com/exc-session/logout?redirect_uri=https://<tenant>.experiencecloud.adobe.com/exc-content/login.html"
  }
}
