{
  "accpDescription": "Adobe Campaign の主要なアセットを監視し、重要な管理タスクを実行",
  "acsDescription": "クロスチャネルのキャンペーンを管理し、メディアのあらゆる状況にわたってパーソナライゼーションを向上",
  "adcloudDescription": "予算に基づいて広告の最適な組み合わせを予測し、メディア計画の実行を自動化",
  "adminDescription": "すべての Experience Cloud ユーザーおよび詳細 (アクセス権、役割、最後にアクセスした情報) を表示",
  "adminconsoleDescription": "Experience Cloud ユーザー、ID およびアプリケーションを管理",
  "analyticsDescription": "顧客のあらゆる行動を収集、整理、分析およびレポート",
  "audiencemanagerDescription": "すべての顧客データを管理し、一意のオーディエンスプロファイルを作成して、ユーザーを正確にターゲット化",
  "bizibleDescription": "B2B マーケティングチャネル、コンテンツアセット、およびキャンペーンに対する売上高は、ROI の実証と向上に帰属します。",
  "cjm-homeDescription": "カスタマージャーニーを調整し、メッセージをデザインおよび配信し、インサイトを得ます",
  "cloudManagerUiDescription": "Adobe アプリケーションを作成、テストおよびデプロイし、カスタムコードのデプロイメントを管理",
  "ddamDescription": "デジタルアセットを簡単に見つけたり、管理したり、すべての共同作業者と共有したりします",
  "experienceManagerUIDescription": "デジタルアセット管理を強力なコンテンツ管理システムと組み合わせて、パーソナライズされたコンテンツ主導のエクスペリエンスを配信",
  "experiencePlatformUIDescription": "アドビのアプリケーションおよびエンタープライズデータにわたって、すべてのエクスペリエンスデータを集計",
  "fireflyDescription": "Experience Platform および Experience Cloud 製品の機能を拡張するために作成された、カスタムクラウドネイティブアプリにアクセス",
  "implementDescription": "Experience Cloud 製品およびサービスやサードパーティソリューションのタグをデプロイおよび管理",
  "journeysDescription": "サードパーティシステムおよび Experience Platform からのデータを使用して、ジャーニーを計画、配信および分析",
  "launchDataCollectionDescription": "Web、モバイル、エッジ、サーバーをまたいで、Adobe Experience Cloud およびその他の宛先にクリックストリームデータを送信します",
  "launchDescription": "Experience Cloud 製品およびサービスやサードパーティソリューションのタグをデプロイおよび管理",
  "marketoDescription": "各チャネルにわたってマーケティングエンゲージメントを自動化および測定し、最もタイムリーで効果的な B2B エクスペリエンスを実現します",
  "placesDescription": "位置認識を含むモバイルアプリを有効にして、位置情報機能およびサービスを使用したアクションを実行",
  "platformAnalyticsDescription": "Experience Platform データを使用してチャネルをまたいでカスタマージャーニーを分析",
  "primetimeDescription": "マルチスクリーンのテレビプラットフォームを使用して、パーソナライズされた魅力的なテレビおよび映画エクスペリエンスを作成",
  "privacyuiDescription": "関連するプライバシー規制に基づいて、顧客のデータアクセスおよび削除リクエストを送信、監視および応答",
  "softwareDistributionDescription": "Adobe Experience Manager および Adobe Campaign のパッケージを簡単に検索、発見およびダウンロード",
  "systemStatusDescription": "アドビクラウド製品のサービス停止、中断およびメンテナンスイベントを監視",
  "targetDescription": "実験、テストおよび機械学習の自動化を使用して、各訪問者に最適なコンテンツを配信"
}
