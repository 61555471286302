{
  "accpDescription": "为 Adobe Campaign 监控关键资产并执行重要管理任务",
  "acsDescription": "管理跨渠道的促销活动，并在您的整个媒体布局中增强个性化",
  "adcloudDescription": "根据预算预测广告的最佳组合，并自动执行媒体计划",
  "adminDescription": "查看所有 Experience Cloud 用户和详细信息，包括访问权限、角色和上次访问信息",
  "adminconsoleDescription": "管理 Experience Cloud 用户、标识和应用程序",
  "analyticsDescription": "收集、整理、分析和报告客户所做的一切",
  "audiencemanagerDescription": "管理所有客户数据，构建独特受众轮廓，并精确定位用户",
  "bizibleDescription": "将收入归因于您的 B2B 营销渠道、内容资源和营销活动以证实和提高 ROI。",
  "cjm-homeDescription": "编排客户历程、设计并传递消息以及获得见解",
  "cloudManagerUiDescription": "生成、测试和部署 Adobe 应用程序，并管理自定义代码部署",
  "ddamDescription": "轻松地发现、管理数字资源并与所有协作者共享",
  "experienceManagerUIDescription": "通过将数字资产管理与强大的内容管理系统结合使用，交付以内容为导向的个性化体验",
  "experiencePlatformUIDescription": "汇总不同 Adobe 应用程序中的所有体验数据和企业数据",
  "fireflyDescription": "访问您为了扩展 Experience Platform 和 Experience Cloud 产品功能而构建的自定义云原生应用程序",
  "implementDescription": "为 Experience Cloud 产品和服务以及第三方解决方案部署和管理标记",
  "journeysDescription": "运用来自第三方系统和 Experience Platform 的数据，开启规划、交付和分析的旅程",
  "launchDataCollectionDescription": "将点击流数据发送到 Adobe Experience Cloud 以及 Web、移动设备、边缘和服务器间的其他目标",
  "launchDescription": "为 Experience Cloud 产品和服务以及第三方解决方案部署和管理标记",
  "marketoDescription": "在各个渠道间自动开展并衡量营销活动，以营造及时而有效的 B2B 体验",
  "placesDescription": "启用具有位置感知功能的移动应用程序，以便通过地理定位功能和服务采取相应行动",
  "platformAnalyticsDescription": "使用 Experience Platform 数据跨渠道分析客户历程",
  "primetimeDescription": "通过多屏电视平台，创造引人入胜的个性化影视体验",
  "privacyuiDescription": "根据相关隐私法规，提交、监控和响应客户访问和删除数据的请求",
  "softwareDistributionDescription": "轻松搜索、发现并下载 Adobe Experience Manager 和 Adobe Campaign 软件包",
  "systemStatusDescription": "监控 Adobe 云产品的服务暂停、中断和维护事件",
  "targetDescription": "利用实验、测试和机器学习自动化，为每位访客提供最佳内容"
}
