{
  "accpDescription": "Monitore os principais ativos e execute tarefas administrativas importantes para o Adobe Campaign",
  "acsDescription": "Gerencie campanhas entre canais e melhore a personalização em todo o seu cenário de mídia",
  "adcloudDescription": "Preveja a melhor combinação de anúncios com base no orçamento e automatize a execução do seu plano de mídia",
  "adminDescription": "Veja todos os usuários e detalhes da Experience Cloud, como acesso, função e informações acessadas pela última vez",
  "adminconsoleDescription": "Gerencie usuários, identidades e aplicativos da Experience Cloud",
  "analyticsDescription": "Colete, organize, analise e relate tudo o que seus clientes fazem",
  "audiencemanagerDescription": "Gerencie todos os dados de seus clientes, construa perfis de público-alvo exclusivos e direcione com precisão seus usuários",
  "bizibleDescription": "Atribua receita aos seus canais de marketing B2B, ativos de conteúdo e campanhas para provar e melhorar o ROI.",
  "cjm-homeDescription": "Orquestrar as jornadas dos clientes, projetar e entregar mensagens e obter insights",
  "cloudManagerUiDescription": "Crie, teste e implante aplicativos da Adobe e gerencie suas implantações de código personalizadas",
  "ddamDescription": "Descubra, gerencie e compartilhe facilmente ativos digitais com todos os colaboradores",
  "experienceManagerUIDescription": "Ofereça experiências personalizadas determinadas por conteúdo combinando gerenciamento de ativos digitais com um eficiente sistema de gerenciamento de conteúdo",
  "experiencePlatformUIDescription": "Agregue todos os seus dados de experiência em aplicativos da Adobe e dados corporativos",
  "fireflyDescription": "Acesse seus aplicativos nativos de nuvem personalizados desenvolvidos para estender a funcionalidade dos produtos da Experience Platform e da Experience Cloud",
  "implementDescription": "Implante e gerencie tags para produtos e serviços da Experience Cloud e soluções de terceiros",
  "journeysDescription": "Planejar, entregar e analisar jornadas usando dados de sistemas de terceiros e da Experience Platform",
  "launchDataCollectionDescription": "Enviar dados de sequência de cliques para a Adobe Experience Cloud e outros destinos na web, dispositivo móvel, borda e servidor",
  "launchDescription": "Implante e gerencie tags para produtos e serviços da Experience Cloud e soluções de terceiros",
  "marketoDescription": "Automatize e avalie o envolvimento de marketing em todos os canais para oferecer as experiências B2B mais oportunas e eficientes",
  "placesDescription": "Habilite aplicativos móveis com reconhecimento de local para executar ações por meio de serviços e recursos de localização geográfica",
  "platformAnalyticsDescription": "Analise as jornadas dos clientes entre canais usando os dados da Experience Platform",
  "primetimeDescription": "Crie experiências de TV e filmes atraentes e personalizadas por meio de uma plataforma de TV com várias telas",
  "privacyuiDescription": "Envie, monitore e responda ao acesso aos dados dos clientes e exclua solicitações de acordo com os regulamentos de privacidade relevantes",
  "softwareDistributionDescription": "Pesquise, descubra e baixe facilmente pacotes para o Adobe Experience Manager e o Adobe Campaign",
  "systemStatusDescription": "Monitore eventos de interrupção e manutenção de serviços para produtos em nuvem da Adobe",
  "targetDescription": "Use automação de experimentação, teste e aprendizado de máquina para fornecer o melhor conteúdo para cada visitante"
}
