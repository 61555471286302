[
  {
    "adobeBrandType": "application",
    "appId": "accp",
    "defaultDescription": "Monitor key assets and perform important administrative tasks for Adobe Campaign",
    "name": "Campaign Control Panel",
    "functionalIconReact": "EmailGear",
    "serviceCode": ["dma_campaign", "dma_campaign_classic"],
    "default": false,
    "showInSwitcher": false,
    "showInQuickAccess": true
  },
  {
    "adobeBrandType": "cloud",
    "appId": "admin",
    "defaultDescription": "View all Experience Cloud users and details including access, role, and last accessed information",
    "name": "Admin",
    "longName": "Admin Tool",
    "functionalIconReact": "UserAdmin",
    "roles": ["org_admin"],
    "default": false,
    "showInSwitcher": false,
    "showInQuickAccess": true
  },
  {
    "appId": "adminconsole",
    "defaultDescription": "Manage Experience Cloud users, identity, and applications",
    "name": "Admin Console",
    "longName": "Admin Console",
    "href": {
      "dev": "https://qa.adminconsole.adobe.com",
      "qa": "https://stage.adminconsole.adobe.com",
      "stage": "https://stage.adminconsole.adobe.com",
      "prod": "https://adminconsole.adobe.com"
    },
    "roles": ["org_admin", "user_admin_org", "PRODUCT_ADMIN", "user_admin_grp", "USER_GROUP_ADMIN", "SUPPORT_ADMIN"],
    "functionalIconReact": "AdminConsole",
    "default": false,
    "showInSwitcher": true,
    "showInQuickAccess": true
  },
  {
    "appId": "assets",
    "name": "Assets",
    "learnMoreLink":"https://www.adobe.com/marketing/experience-manager-assets/dynamic-media.html",
    "longName": "Assets",
    "href": {
      "dev": "https://<tenant>.experience-dev.corp.adobe.com/content/mac/<tenant>.html/assets",
      "qa": "https://<tenant>.experience-qa.corp.adobe.com/content/mac/<tenant>.html/assets",
      "stage": "https://<tenant>.experience-qa.corp.adobe.com/content/mac/<tenant>.html/assets",
      "prod": "https://<tenant>.experiencecloud.adobe.com/content/mac/<tenant>.html/assets"
    },
    "functionalIconReact": "Asset",
    "default": true,
    "showInSwitcher": true,
    "access": {
      "flag": "core-assets-enabled"
    },
    "alternateDestination": [{
      "access": {"flag": "core-services-enabled"},
      "path": "/core-services/assets"
    }]
  },
  {
    "adobeBrandType": "service",
    "appId": "cloudManagerUi",
    "defaultDescription": "Build, test and deploy Adobe applications, and manage your custom code deployments",
    "name": "Cloud Manager",
    "longName": "Cloud Manager",
    "default": false,
    "showInSwitcher": false
  },
  {
    "adobeBrandType": "service",
    "appId": "ddam",
    "defaultDescription": "Easily discover, manage and share digital assets with all collaborators",
    "longName": "Adobe Experience Manager Assets",
    "name": "Experience Manager Assets",
    "functionalIconReact": "Asset",
    "default": false,
    "showInSwitcher": true,
    "showInQuickAccess": true
  },
  {
    "appId": "exchange",
    "learnMoreLink":"https://www.adobeexchange.com/experiencecloud",
    "name": "Exchange",
    "longName": "Exchange",
    "href": {
      "dev": "https://exchange.adobe.com/experiencecloud",
      "qa": "https://exchange.adobe.com/experiencecloud",
      "stage": "https://exchange.adobe.com/experiencecloud",
      "prod": "https://exchange.adobe.com/experiencecloud"
    },
    "default": true,
    "showInSwitcher": true
  },
  {
    "adobeBrandType": "platform",
    "appId": "experiencePlatformUI",
    "defaultDescription": "Aggregate all your experience data across Adobe applications and enterprise data",
    "learnMoreLink":"https://www.adobe.com/experience-platform.html",
    "name": "Experience Platform",
    "longName": "Adobe Experience Platform",
    "functionalIconReact": "CircleExperiencePlatform",
    "default": true,
    "showInSwitcher": true,
    "showInQuickAccess": true
  },
  {
    "adobeBrandType": "cloud",
    "appId": "firefly",
    "defaultDescription": "Access cloud native apps built within the framework to extend the functionality of Adobe CXM",
    "name": "App Builder Catalog",
    "path": "/custom-apps",
    "functionalIconReact": "AppDevelopment",
    "default": false,
    "showInSwitcher": true,
    "showInQuickAccess": true
  },
  {
    "adobeBrandType": "cloud",
    "appId": "griffon",
    "name": "Adobe Experience Cloud",
    "default": false,
    "showInSwitcher": false
  },
  {
    "adobeBrandType": "application",
    "appId": "hcms",
    "name": "Headless CMS",
    "default": false,
    "showInSwitcher": false
  },
  {
    "adobeBrandType": "service",
    "appId": "launchDataCollection",
    "defaultDescription": "Send clickstream data to Adobe Experience Cloud and other destinations across web, mobile, edge, and server",
    "learnMoreLink":"https://www.adobe.com/experience-platform/launch.html",
    "name": "Data Collection",
    "longName": "Adobe Experience Platform Data Collection",
    "functionalIconReact": "Launch",
    "default": true,
    "showInSwitcher": true,
    "showInQuickAccess": true
  },
  {
    "adobeBrandType": "service",
    "appId": "journeysNextAdmin",
    "defaultDescription": "Plan, deliver and analyze journeys with Experience Platform-based segments",
    "learnMoreLink":"https://docs.adobe.com/content/help/en/journeys/using/starting-with-journeys/about-journey-orchestration.html",
    "name": "Journey Orchestration",
    "longName": "Journey Orchestration",
    "functionalIconReact": "JourneyOrchestration",
    "default": true,
    "showInSwitcher": true,
    "showInQuickAccess": true
  },
  {
    "appId": "offers",
    "learnMoreLink":"http://www.adobe.com/marketing-cloud",
    "name": "Offers",
    "longName": "Offer Management",
    "href": {
      "dev": "https://experience-dev.corp.adobe.com/offers",
      "qa": "https://experience-qa.corp.adobe.com/offers",
      "stage": "https://experiencecloud-stage.adobe.com/offers",
      "prod": "https://experiencecloud.adobe.com/offers"
    },
    "serviceCode": ["dma_offers"],
    "default": false,
    "showInSwitcher": true,
    "access": {
      "flag": "exc-offers-enabled"
    }
  },
  {
    "appId": "people",
    "learnMoreLink": "https://www.adobe.com/experience-platform/people.html",
    "name": "People",
    "longName": "People",
    "href": {
      "dev": "https://<tenant>.experience-dev.corp.adobe.com/audiences.html/<tenant>/audiences/folder",
      "qa": "https://<tenant>.experience-qa.corp.adobe.com/audiences.html/<tenant>/audiences/folder",
      "stage": "https://<tenant>.experience-qa.corp.adobe.com/audiences.html/<tenant>/audiences/folder",
      "prod": "https://<tenant>.experiencecloud.adobe.com/audiences.html/<tenant>/audiences/folder"
    },
    "serviceCode": ["dma_audience_library", "dma_crs"],
    "default": false,
    "showInSwitcher": true,
    "alternateDestination": [{
      "access": {"flag": "core-services-enabled"},
      "path": "/core-services/audiences"
    }]
  },
  {
    "adobeBrandType": "platform",
    "appId": "platformAnalytics",
    "defaultDescription": "Analyze customer journeys across channels using Experience Platform data",
    "longName": "Customer Journey Analytics",
    "name": "Customer Journey Analytics",
    "functionalIconReact": "UserAnalytics",
    "default": false,
    "showInSwitcher": true,
    "showInQuickAccess": true
  },
  {
    "appId": "privacyui",
    "defaultDescription": "Submit, monitor and respond to customers’ data access and delete requests under relevant privacy regulations",
    "functionalIconReact": "Privacy",
    "href": {
      "dev": "https://privacyui-QA-va7.dev.cloud.adobe.io",
      "qa": "https://privacyui-QA-va7.dev.cloud.adobe.io",
      "stage": "https://privacyui-QA-va7.dev.cloud.adobe.io",
      "prod": "https://privacyui.cloud.adobe.io"
    },
    "name": "Privacy UI",
    "roles": ["org_admin", "PRODUCT_ADMIN"],
    "accessPermissions": {
      "fi": ["acp:acp_privacy"],
      "subServiceCode": ["dma_privacy:acp_privacy"],
      "permRolesLogicOp": "OR"
    },
    "default": false,
    "showInSwitcher": false,
    "showInQuickAccess": true
  },
  {
    "adobeBrandType": "service",
    "appId": "softwareDistribution",
    "defaultDescription": "Easily search for, discover and download of packages for Adobe Experience Manager and Adobe Campaign",
    "name": "Software Distribution",
    "functionalIconReact": "BoxSearch",
    "default": true,
    "showInSwitcher": ["dma_aem_ams", "dma_aem_cloud", "dma_campaign", "dma_mpod", "dma_scene7"],
    "showInQuickAccess": true
  },
  {
    "appId": "systemStatus",
    "defaultDescription": "Monitor services outage, disruption and maintenance events for Adobe cloud products",
    "functionalIconReact": "Gauge",
    "href": {
      "dev": "https://dev.status.adobe.com",
      "qa": "https://dev.status.adobe.com",
      "stage": "https://status.stage.adobe.com",
      "prod": "https://status.adobe.com"
    },
    "name": "System Status",
    "default": true,
    "showInSwitcher": false,
    "showInQuickAccess": true
  },
  {
    "adobeBrandType": "service",
    "appId": "cjm-home",
    "defaultDescription": "Orchestrate customer journeys, design & deliver messages, and gain insights",
    "name": "Journey Optimizer",
    "longName": "Adobe Journey Optimizer",
    "path": "/journey-optimizer/home",
    "functionalIconReact": "CircleJourneyOptimizer",
    "default": false,
    "showInSwitcher": true,
    "showInQuickAccess": true
  },
  {
    "adobeBrandType": "application",
    "appId": "hummingbird",
    "defaultDescription": "",
    "name": "Dynamic Chat",
    "longName": "Adobe Dynamic Chat",
    "functionalIconReact": "DynamicChat",
    "default": false,
    "showInSwitcher": true,
    "showInQuickAccess": true
  },
  {
    "adobeBrandType": "application",
    "appId": "aemxmngr",
    "name": "Experience Manager",
    "longName": "Adobe Experience Manager",
    "functionalIconReact": "ExperienceManager",
    "default": false,
    "showInSwitcher": false
  },
  {
    "adobeBrandType": "application",
    "appId": "aem-cf-admin",
    "name": "Experience Manager",
    "longName": "Adobe Experience Manager",
    "functionalIconReact": "ExperienceManager",
    "default": false,
    "showInSwitcher": false
  },
  {
    "adobeBrandType": "application",
    "appId": "aem-cf-editor",
    "name": "Experience Manager",
    "longName": "Adobe Experience Manager",
    "functionalIconReact": "ExperienceManager",
    "default": false,
    "showInSwitcher": false
  },
  {
    "adobeBrandType": "application",
    "appId": "releaseToggles",
    "name": "Experience Manager",
    "longName": "Adobe Experience Manager",
    "functionalIconReact": "ExperienceManager",
    "default": false,
    "showInSwitcher": false
  },
  {
    "adobeBrandType": "application",
    "appId": "aem-migration",
    "name": "Experience Manager",
    "longName": "Adobe Experience Manager",
    "functionalIconReact": "ExperienceManager",
    "default": false,
    "showInSwitcher": false
  },
  {
    "adobeBrandType": "application",
    "appId": "aemshell",
    "name": "Experience Manager",
    "longName": "Adobe Experience Manager",
    "functionalIconReact": "ExperienceManager",
    "default": false,
    "showInSwitcher": false
  },
  {
    "adobeBrandType": "service",
    "appId": "screens",
    "name": "Experience Manager",
    "longName": "Adobe Experience Manager",
    "functionalIconReact": "ExperienceManager",
    "default": false,
    "showInSwitcher": false
  },
  {
    "adobeBrandType": "application",
    "appId": "marketo",
    "defaultDescription": "Automate and measure marketing engagement across every channel to deliver the most timely and effective B2B experiences",
    "name": "Marketo Engage",
    "longName": "Adobe Marketo Engage",
    "functionalIconReact": "MarketoEngage",
    "default": false,
    "showInSwitcher": true,
    "showInQuickAccess": true
  },
  {
    "adobeBrandType": "application",
    "appId": "marketoEngage",
    "defaultDescription": "Automate and measure marketing engagement across every channel to deliver the most timely and effective B2B experiences",
    "name": "Marketo Engage",
    "longName": "Adobe Marketo Engage",
    "functionalIconReact": "MarketoEngage",
    "default": false,
    "showInSwitcher": false
  },
  {
    "adobeBrandType": "application",
    "appId": "bizible",
    "defaultDescription": "Attribute revenue to your B2B marketing channels, content assets, and campaigns to prove and improve ROI",
    "name": "Marketo Measure",
    "longName": "Adobe Marketo Measure",
    "functionalIconReact": "GraphBarMoney",
    "default": false,
    "showInSwitcher": true,
    "showInQuickAccess": true
  },
  {
    "adobeBrandType": "application",
    "appId": "triggers",
    "defaultDescription": "",
    "name": "Triggers",
    "longName": "Adobe Experience Cloud Triggers",
    "href": {
      "dev": "https://<tenant>.experience-qa.corp.adobe.com/triggers.html/<tenant>/triggers/list",
      "qa": "https://<tenant>.experience-qa.corp.adobe.com/triggers.html/<tenant>/triggers/list",
      "stage": "https://<tenant>.experiencecloud-stage.adobe.com/triggers.html/<tenant>/triggers/list",
      "prod": "https://<tenant>.experiencecloud.adobe.com/triggers.html/<tenant>/triggers/list"
    },
    "functionalIconReact": "",
    "default": false,
    "showInSwitcher": true,
    "accessPermissions": {
      "code": ["dma_analytics"],
      "flag": "enable-triggers-subservice",
      "logicOp": "AND",
      "subServiceCode": ["triggers"]
    },
    "alternateDestination": [
      {
        "access": {
          "code": ["dma_campaign", "dma_campaign_classic"],
          "logicOp": "AND",
          "subServiceCode": ["dma_analytics:triggers"]
        },
        "path": "/triggers"
      },
      {
        "access": {
          "flag": "triggers-bypass-enabled",
          "logicOp": "AND",
          "subServiceCode": ["dma_analytics:triggers"]
        },
        "path": "/triggers/v2"
      }
    ]
  },
  {
    "appId": "permissionsUI",
    "defaultDescription": "Unified Permissions",
    "name": "Permissions",
    "longName": "Permissions",
    "path": "/admin/permissions",
    "roles": ["org_admin","PRODUCT_ADMIN"],
    "functionalIconReact": "UserAdmin",
    "default": false,
    "showInSwitcher": false,
    "showInQuickAccess": true
  },
  {
    "adobeBrandType": "workfront",
    "appId": "workfront",
    "defaultDescription": "Connect, collaborate, and execute on complex workflows—from anywhere.",
    "name": "Workfront",
    "longName": "Adobe Workfront",
    "path": "/workfront",
    "functionalIconReact": "WorkfrontCircle",
    "default": false,
    "showInSwitcher": true,
    "showInQuickAccess": true
  },
  {
    "adobeBrandType": "workfront",
    "appId": "fusion",
    "defaultDescription": "All the tools you need, all together.",
    "name": "Workfront Fusion",
    "longName": "Adobe Workfront Fusion",
    "path": "/fusion",
    "functionalIconReact": "Fusion",
    "default": false,
    "showInSwitcher": true,
    "showInQuickAccess": true
  },
  {
    "adobeBrandType": "service",
    "appId": "sapphireHome",
    "defaultDescription": "Journey Optimizer B2B Edition",
    "name": "Journey Optimizer B2B Edition",
    "longName": "Adobe Journey Optimizer B2B Edition",
    "path": "/journey-optimizer-b2b/home",
    "functionalIconReact": "Sapphire",
    "default": false,
    "showInSwitcher": true,
    "showInQuickAccess": true
  },
  {
    "adobeBrandType": "service",
    "appId": "advertising-ssc",
    "defaultDescription": "Advertising Search, Social & Commerce",
    "name": "Search, Social, & Commerce",
    "longName": "Adobe Advertising Search, Social, & Commerce",
    "path": "/advertising/ssc",
    "default": false,
    "showInSwitcher": false,
    "showInQuickAccess": false
  },
  {
    "adobeBrandType": "application",
    "appId": "aemGenerateVariations",
    "defaultDescription": "Adobe Experience Manager AI Assistant",
    "learnMoreLink": "https://www.adobe.com/marketing/experience-manager.html",
    "name": "Experience Manager",
    "longName": "Adobe Experience Manager",
    "functionalIconReact": "ExperienceManager",
    "default": true,
    "showInSwitcher": false,
    "showInQuickAccess": false
  },
  {
    "adobeBrandType": "application",
    "appId": "aemExperienceSuccessStudio",
    "defaultDescription": "Adobe Experience Manager Experience Success Studio",
    "learnMoreLink": "https://www.adobe.com/marketing/experience-manager.html",
    "name": "Experience Manager",
    "longName": "Adobe Experience Manager",
    "functionalIconReact": "ExperienceManager",
    "default": true,
    "showInSwitcher": false,
    "showInQuickAccess": false
  }
]
