{
  "accpDescription": "Adobe Campaign의 주요 자산 모니터링 및 중요한 관리 작업 수행",
  "acsDescription": "교차 채널 캠페인 관리 및 전체 미디어 환경에서 개인 맞춤화 개선",
  "adcloudDescription": "예산에 따른 최고의 광고 혼합 예측 및 미디어 플랜 실행 자동화",
  "adminDescription": "모든 Experience Cloud 사용자와 액세스, 역할 및 마지막으로 액세스한 정보를 포함한 세부 정보 보기",
  "adminconsoleDescription": "Experience Cloud 사용자, ID 및 애플리케이션 관리",
  "analyticsDescription": "고객이 수행하는 모든 항목 수집, 구성, 분석 및 보고",
  "audiencemanagerDescription": "모든 고객 데이터 관리, 고유 대상자 프로필 구축 및 정확한 사용자 대상 지정",
  "bizibleDescription": "ROI를 승인 및 개선하기 위해 매출을 B2B 마케팅 채널, 콘텐츠 자산과 캠페인에 연결하세요.",
  "cjm-homeDescription": "고객 여정 조율, 메시지 디자인 및 전달, 통찰력 확보",
  "cloudManagerUiDescription": "Adobe 애플리케이션 구축, 테스트, 배포 및 사용자 지정 코드 배포 관리",
  "ddamDescription": "간편하게 디지털 자산을 검색 및 관리하고 모든 공동 작업자와 공유하세요.",
  "experienceManagerUIDescription": "디지털 자산 관리를 강력한 콘텐츠 관리 시스템과 결합하여 맞춤형 콘텐츠 주도 환경 제공",
  "experiencePlatformUIDescription": "Adobe 애플리케이션 및 엔터프라이즈 데이터 전반에 걸쳐 모든 경험 데이터 집계",
  "fireflyDescription": "Experience Platform 및 Experience Cloud 제품의 기능을 확장하기 위해 구축된 맞춤형 클라우드 네이티브 앱에 액세스",
  "implementDescription": "Experience Cloud 제품 및 서비스와 서드 파티 솔루션에 대한 태그 배포 및 관리",
  "journeysDescription": "서드 파티 시스템 및 Experience Platform의 데이터를 사용하여 여정을 계획, 전달 및 분석",
  "launchDataCollectionDescription": "클릭스트림 데이터를 Adobe Experience Cloud 및 웹, 모바일, 에지, 서버에 있는 다른 대상으로 전송",
  "launchDescription": "Experience Cloud 제품 및 서비스와 서드 파티 솔루션에 대한 태그 배포 및 관리",
  "marketoDescription": "모든 채널에 걸쳐 Marketing Engagement를 자동화하고 측정하여 가장 시기 적절하고 유효한 B2B 경험을 제공하세요.",
  "placesDescription": "모바일 앱의 위치 인식을 활성화하여 지리적 위치 기능 및 서비스를 활용하기",
  "platformAnalyticsDescription": "Experience Platform 데이터를 사용하여 채널에서 고객 여정 분석",
  "primetimeDescription": "다중 화면 TV 플랫폼을 통해 흥미로운 맞춤형 TV 및 영화 환경 만들기",
  "privacyuiDescription": "고객의 데이터 액세스 제출, 모니터링, 대응 및 관련 개인 정보 규정에 따라 요청 삭제",
  "softwareDistributionDescription": "Adobe Experience Manager 및 Adobe Campaign 패키지를 간편하게 검색, 발견 및 다운로드",
  "systemStatusDescription": "Adobe 클라우드 제품에 대한 서비스 중단 및 유지 관리 이벤트 모니터링",
  "targetDescription": "실험, 테스트 및 머신 러닝 자동화를 사용하여 각 방문자에게 최고의 콘텐츠 제공"
}
