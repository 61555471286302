/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {getAllSubOrgsForApp} from './ContextService/SubOrgService';
import {getBrandIcon} from './AppAssemblyConstants/icons';
import {hasPermission, hasValidRole} from '../utils/permission';
import landingPage from './AppAssemblyConstants/landingPage';
import profileComponent from './AppAssemblyConstants/profileComponent';
import {RELEASE_TYPE} from '../models/Solution';
import services from './AppAssemblyConstants/services';
import solutions from './AppAssemblyConstants/solutions';
import * as translations from './__localization__/AppAssemblyServiceStrings';

/* eslint-disable sort-keys */
const BASE_URL = {
  dev: 'https://experience-qa.adobe.com/#',
  qa: 'https://experience-qa.adobe.com/#',
  stage: 'https://experience-stage.adobe.com/#',
  prod: 'https://experience.adobe.com/#'
};

/**
 * Helper function to determine if the path of a config and fixture match
 * @param {Object} config - Unified Shell app config
 * @param {Object} fixture - Experience Cloud app fixture
 * @returns {Boolean}
 */
const pathMatch = (config, fixture) => {
  if (!config.redirectFrom) {
    return config.path === fixture.path;
  }
  return config.redirectFrom.some(({path}) => path === fixture.path);
};

const getReleaseType = (isDemoMode, {parent, releaseType}) => {
  if (isDemoMode) {
    return RELEASE_TYPE.GA;
  }
  // Hack to get around the fact that composed applications do not have configs
  // and normally those don't have releaseTypes other than GA.
  if (parent === 'SAPPHIRE') {
    return releaseType;
  }
  return (!parent && releaseType || RELEASE_TYPE.GA);
};

/**
 * Merges list of app data from legacy fixtures and Unified Shell configs
 * @param {array} appConfigs Unified Shell solution configs
 * @param {string} env Current environment
 * @param {boolean} isDemoMode If UnifiedShell is running in demo mode
 * @returns {Array} Merged list of Unified Shell configs and fixtures
 */
const mergeAppList = (appConfigs, env, isDemoMode) => {
  const fixtures = solutions.concat(services);

  const listedApps = appConfigs.reduce((list, config) => {
    // Only include the apps listed in the fixtures because
    // not every config maps to an actual app
    const match = fixtures.find(f => {
      const apps = Array.isArray(f.appId) ? f.appId : [f.appId];
      return apps.indexOf(config.appId) > -1;
    });
    if (match) {
      // Some apps have multiple configs so we only take the
      // configs with a path that matches the fixture
      const multiples = appConfigs.filter(ac => ac.appId === config.appId);
      if ((multiples.length > 1 && pathMatch(config, match)) || multiples.length === 1) {
        const {access: {code = match.serviceCode} = {}, experienceLeague} = config;
        const releaseType = getReleaseType(isDemoMode, config);
        // Create an object from the consistently branded fixture values
        // Use the path and serviceCodes from the app's config if they exist
        // (Fully onboarded apps use their config path, the fixture href is deleted)
        // Add the item to the list of apps with configs
        list.push({
          ...match,
          access: config.access,
          disableIfAppEnabled: config.disableIfAppEnabled,
          experienceLeague,
          href: match.href ? match.href[env] : `${BASE_URL[env]}${match.path || config.path}`,
          releaseType,
          serviceCode: code,
          settingsAppId: config.settingsAppId || '',
          urlContext: config.urlContext
        });
        // And remove the match from the fixtures list, to reduce that list
        fixtures.splice(fixtures.indexOf(match), 1);
      }
    }
    return list;
  }, []);
  // The remaining fixtures are reset so their shape matches those of
  // configured apps, namely the href as a string, not an object
  const remainingFixtures = fixtures.filter(f => f.href).map(f => ({...f, href: f.href[env]}));
  return listedApps.concat(remainingFixtures);
};

export default class AppAssemblyService {
  constructor(shellConfig) {
    // Ultimately we'll have one, flat list of all DX solutions, services and apps
    // Currently this list is created by merging onboarded solutions and fixtures
    this.listedApps = mergeAppList(shellConfig.listedApps, shellConfig.environment, shellConfig.isDemoMode);
    this.environment = shellConfig.environment;
    this.featureFlags = {};
    this.locale = shellConfig.locale.replace(/-/g, '_');
    this.profile = shellConfig.profile;
    this.sandbox = shellConfig.sandbox || {};
    this.userFulfillableData = shellConfig.userFulfillableData;
    this.userFulfillableItems = shellConfig.userFulfillableItems;
    this.userRoles = shellConfig.userRoles || [];
    this.userServiceCodes = shellConfig.userServiceCodes;
    this.userSubServices = shellConfig.userSubServices;
    this.userVisibleNames = shellConfig.userVisibleNames;
  }

  /**
   * Gets the landing page object with correct href
   * @returns {Object} Item includes name, href, longName
   */
  getLandingPage() {
    const {
      adobeBrandType,
      appId,
      longName,
      name
    } = landingPage;
    return {
      adobeBrandType,
      appId,
      href: `${BASE_URL[this.environment]}${landingPage.path}`,
      longName,
      name
    };
  }

  /**
   * Gets correct user profile endpoints
   * @param {string} currentTenant name of current tenant
   * @returns {Object} User specific endpoints for user profile
   */
  getProfileComponent(currentTenant) {
    return {
      editProfileLink: this.setHref(profileComponent.editprofile[this.environment], currentTenant),
      signOutLink: this.setHref(profileComponent.logout[this.environment], currentTenant),
      userAvatarUrl: this.profile.avatar,
      userName: this.profile.displayName,
      userTitle: this.profile.job_function
    };
  }

  /**
   * Whether the solution is enabled for the user on the current org
   * @param {Object} itemConfig The current config for an individual service or solution
   * @param {string} currentOrg Current org ID
   * @returns {boolean}
   */
  isEnabled(itemConfig, currentOrg) {
    const {access: {code, flag, visibleName} = {}} = itemConfig;
    const hasAccessPermissions = !!itemConfig.accessPermissions;
    const hasRoles = !!itemConfig.roles;
    const hasFeatureFlag = !!flag;
    const hasServiceCodes = !!code || !!itemConfig.serviceCode;
    const hasVisibleNames = !!visibleName || !!itemConfig.visibleName;
    const {permRolesLogicOp = 'AND'} = itemConfig.accessPermissions || {};

    // None of the validation properties were set on this app, so we fall back
    // to the default enabled value (or false if undefined);
    if (!hasFeatureFlag && !hasRoles && !hasServiceCodes && !hasAccessPermissions && !hasVisibleNames) {
      return itemConfig.default || false;
    }

    const validRoles = !itemConfig.roles ? true : hasValidRole(itemConfig.roles, currentOrg, this.userRoles);
    // Some apps only have service code information in the existing fixtures
    // So we want to use the access information from a solution config
    // if it exists, otherwise use the serviceCode in the fixtures
    let access = itemConfig.access || {code: itemConfig.serviceCode};
    // Privacy UI and Triggers are special cases - legacy apps that require
    // more complicated enablement values than other legacy fixtures have.
    // So we use accessPermissions which mirrors a solution config access object.
    if (['privacyui', 'triggers'].includes(itemConfig.appId)) {
      access = itemConfig.accessPermissions;
    }

    const validFlagAndCode = hasPermission(access, {
      featureFlags: this.featureFlags,
      fulfillableData: this.userFulfillableData[currentOrg],
      fulfillableItems: this.userFulfillableItems[currentOrg],
      serviceCodes: this.userServiceCodes[currentOrg],
      userSubServices: this.userSubServices[currentOrg],
      userVisibleNames: this.userVisibleNames[currentOrg]
    });
    return permRolesLogicOp === 'AND' ?
      validRoles && validFlagAndCode :
      validRoles || validFlagAndCode;
  }

  /**
   * Creates a list of shell services and solutions objects
   * @param {string} listType Flags logic for service vs solution
   * @param {string} currentOrg Current org ID
   * @param {string} currentTenant current tenant name
   * @param {object} unifiedShellSessionData Unified shell session info
   * @param {object} lastSelectedSubOrgs Object containing last used suborgs by org and service code
   * @returns {Array} Returns list with of objects, each with name, longName,
   * description, learnMoreLink, enabled, href, and visible
   */
  populateSolutionSwitcherList(listType, currentOrg, currentTenant, unifiedShellSessionData, lastSelectedSubOrgs) {
    // Filter the list by listType
    // Services have a default property, solutions do not
    let list = listType === 'services' ?
      /* eslint-disable no-prototype-builtins */
      this.listedApps.filter(app => app.hasOwnProperty('default')) :
      /* eslint-disable no-prototype-builtins */
      this.listedApps.filter(app => !app.hasOwnProperty('default'));

    list = list.map(itemConfig => {
      const {
        adobeBrandType,
        alternateDestination,
        appId,
        disableIfAppEnabled,
        experienceLeague,
        functionalIconReact,
        learnMoreLink,
        longName,
        name,
        releaseType, // demo query param should be honored here from mergeAppList
        settingsAppId,
        showInQuickAccess = false,
        urlContext
      } = itemConfig;
      const item = {
        adobeBrandType,
        appId,
        experienceLeague,
        functionalIconReact,
        functionalIconUrl: getBrandIcon(functionalIconReact),
        learnMoreLink,
        longName,
        name,
        releaseType, // demo query param should be honored here from mergeAppList
        settingsAppId,
        showInQuickAccess
      };
      // Add suborgs for current org to suborg-enabled applications
      if (urlContext?.key === 'subOrg' && unifiedShellSessionData) {
        item.subOrgs = getAllSubOrgsForApp(
          this.profile,
          urlContext.config,
          currentOrg,
          unifiedShellSessionData,
          lastSelectedSubOrgs
        );
      }
      // Get localized description if it exists
      item.description = translations[this.locale][`${item.appId}Description`] || '';
      // Passing through the propery so it can be used as cleanup a little lower.
      item.disableIfAppEnabled = disableIfAppEnabled;
      // Check enablement
      item.enabled = this.isEnabled(itemConfig, currentOrg);
      // Get and format the href
      item.href = this.setHref(itemConfig.href, currentTenant);
      // Support alternate path, which changes the location of the application
      // based on the configured feature flag.
      if (alternateDestination) {
        const {path} = alternateDestination.find(({access}) =>
          hasPermission(access, {
            featureFlags: this.featureFlags,
            fulfillableData: this.userFulfillableData[currentOrg],
            fulfillableItems: this.userFulfillableItems[currentOrg],
            serviceCodes: this.userServiceCodes[currentOrg],
            userSubServices: this.userSubServices[currentOrg],
            userVisibleNames: this.userVisibleNames[currentOrg]
          })
        ) ?? {};
        if (path) {
          item.href = this.setHref(`${BASE_URL[this.environment]}${path}`, currentTenant);
        }
      }
      // Admin console is a special case.
      if (item.appId === 'adminconsole') {
        item.href = `${item.href}/${currentOrg}/overview`;
      }
      // Visible determines if the app shows up the ShellAppSwitcher
      item.visible = this.isVisible(listType, itemConfig, item.enabled, currentOrg);
      return item;
    });

    const appIdMap = {};
    // Add the list items into a map so that they can be easily pulled when
    // processing the disableIfAppEnabled configuration.
    list.forEach(item => appIdMap[item.appId] = item);
    // Looks through the map and disables apps based on the disableIfAppEnabled
    // configuration property. This needs to be done last so that all of the
    // enabled statuses can be calculated.
    return list.map(itemConfig => {
      if (itemConfig.enabled && (itemConfig.disableIfAppEnabled || []).length) {
        itemConfig.enabled = !itemConfig.disableIfAppEnabled.some(id => appIdMap[id].enabled);
        itemConfig.visible = itemConfig.visible && itemConfig.enabled;
      }
      delete itemConfig.disableIfAppEnabled;
      return itemConfig;
    });
  }

  /**
   * Determines whether the app should be displayed within the app switcher.
   * @param {string} listType The list type of the application (solution vs service).
   * @param {object} itemConfig Item config.
   * @param {boolean} enabled If the application is enabled.
   * @param {string} currentOrg Id for the currently selected org
   * @returns {boolean} If the app should be visible or not.
   */
  isVisible(listType, {showInSwitcher}, enabled, currentOrg) {
    // A solution is only visible if enabled
    if (listType === 'solutions') {
      return enabled;
    }
    // The service is enabled if:
    // 1. showInSwitcher is an array of serviceCodes and
    // the user has access to any on the current org
    // 2. showInSwitcher is a Boolean and true
    return enabled && (Array.isArray(showInSwitcher) ?
      (this.userServiceCodes[currentOrg] || []).some(code => showInSwitcher.includes(code)) :
      showInSwitcher);
  }

  /**
   * Gets the feature flag and defaults to provided default if not found.
   * @param {string} flag The flag name to get.
   * @param {boolean} defaultValue The default value if flag is not found.
   * @returns {boolean} The feature flag value or the default value.
   */
  getFeatureFlag(flag, defaultValue = false) {
    return flag in this.featureFlags ?
      this.featureFlags[flag] === 'true' :
      defaultValue;
  }

  /**
   * Updates the featureFlags instance variable.
   * @param {Object} flags New set of feature flags.
   */
  setFeatureFlags(flags) {
    this.featureFlags = flags;
  }

  /**
   * Updates the fulfillableData instance variable.
   * @param {Object} fulfillableData New set of fulfillable data.
   */
  setFulfillableData(fulfillableData) {
    this.userFulfillableData = fulfillableData;
  }

  /**
   * Updates the fulfillableItems instance variable.
   * @param {Object} fulfillableItems New set of fulfillable items.
   */
  setFulfillableItems(fulfillableItems) {
    this.userFulfillableItems = fulfillableItems;
  }

  /**
   * Sets the sandbox.
   * @param {Object} sandbox New active sandbox.
   */
  setSandbox(sandbox) {
    this.sandbox = sandbox;
  }

  /**
   * Find and replace tenant in href strings
   * @param {string} endpoint The const endpoint for the href
   * @param {string} currentTenant Tenant name to populate href
   * @returns {string} Returns href including the tenant string
   */
  setHref(endpoint, currentTenant) {
    let tenantString = currentTenant;
    // Unified Shell hrefs should use the `@tenant` syntax
    if (/^https:\/\/experience(-qa|-stage)?.adobe.com/.test(endpoint)) {
      tenantString = `@${currentTenant}`;
    }
    return /<tenant>/g.test(endpoint) ? endpoint.replace(/<tenant>/g, tenantString) : endpoint;
  }

  /**
   * Fetch the necessary shell values
   * @returns {Object} Returns an object of the necessary shell data
   */
  fetchShell(currentOrg, currentTenant, unifiedShellSessionData, lastSelectedSubOrgs) {
    const commonArgs = [currentOrg, currentTenant, unifiedShellSessionData, lastSelectedSubOrgs];
    return {
      landingpage: this.getLandingPage(),
      orgId: currentOrg,
      profileComponent: this.getProfileComponent(currentTenant),
      services: this.populateSolutionSwitcherList('services', ...commonArgs),
      solutions: this.populateSolutionSwitcherList('solutions', ...commonArgs),
      status: [{enabled: true, field: 'authenticated'}]
    };
  }
}
