{
  "accpDescription": "Monitora le risorse chiave ed esegui importanti attività di amministrazione per Adobe Campaign",
  "acsDescription": "Gestisci le campagne cross-channel e migliora la personalizzazione per l’intera gamma di media",
  "adcloudDescription": "Prevedi la migliore combinazione di annunci in base al budget e automatizza l’esecuzione del piano mediatico",
  "adminDescription": "Visualizza tutti gli utenti Experience Cloud con dettagli quali accesso, ruolo e ultime informazioni consultate",
  "adminconsoleDescription": "Gestisci gli utenti, le identità e le applicazioni di Experience Cloud.",
  "analyticsDescription": "Raccogli, organizza, analizza e genera rapporti su tutte le attività dei clienti",
  "audiencemanagerDescription": "Gestisci tutti i dati dei clienti, crea profili di pubblico univoci e indirizza con precisione i tuoi utenti",
  "bizibleDescription": "Attribuisci le entrate ai tuoi canali di marketing B2B, agli asset di contenuto e alle campagne per dimostrare e migliorare il ritorno sull’investimento.",
  "cjm-homeDescription": "Coordina i percorsi del cliente, progetta e consegna messaggi e acquisisci informazioni",
  "cloudManagerUiDescription": "Genera, testa e implementa applicazioni Adobe, e gestisci le tue implementazioni di codice personalizzato",
  "ddamDescription": "Trova, gestisci e condividi con i collaboratori le risorse digitali",
  "experienceManagerUIDescription": "Distribuisci esperienze personalizzate e basate sui contenuti, combinando insieme la gestione delle risorse digitali e un potente sistema per la gestione dei contenuti",
  "experiencePlatformUIDescription": "Aggrega tutti i dati relativi alle esperienze provenienti dalle applicazioni Adobe e dai dati aziendali",
  "fireflyDescription": "Accedi alle tue app cloud native personalizzate che consentono di estendere le funzionalità di Experience Platform e dei prodotti Experience Cloud",
  "implementDescription": "Implementa e gestisci i tag per i prodotti e i servizi Experience Cloud e per soluzioni di terze parti",
  "journeysDescription": "Pianifica, distribuisci e analizza i percorsi utilizzando dati da sistemi di terze parti ed Experience Platform",
  "launchDataCollectionDescription": "Invia dati clickstream ad Adobe Experience Cloud e altre destinazioni in diversi ambienti: web, mobile, rete edge e server",
  "launchDescription": "Implementa e gestisci i tag per i prodotti e i servizi Experience Cloud e per soluzioni di terze parti",
  "marketoDescription": "Automatizza e misura il coinvolgimento marketing in ogni canale, per offrire esperienze B2B sempre tempestive ed efficaci",
  "placesDescription": "Sfrutta le funzioni e i servizi di geolocalizzazione affinché le app mobili possano tener conto della posizione",
  "platformAnalyticsDescription": "Analizza i percorsi dei clienti nei vari canali utilizzando i dati Experience Platform",
  "primetimeDescription": "Crea esperienze coinvolgenti e personalizzate per TV e film tramite una piattaforma TV multischermo",
  "privacyuiDescription": "Invia, monitora e rispondi alle richieste dei clienti che desiderano accedere ai propri dati o eliminarli, in conformità alle normative sulla privacy",
  "softwareDistributionDescription": "Cerca, individua e scarica facilmente i pacchetti per Adobe Experience Manager e Adobe Campaign",
  "systemStatusDescription": "Controlla gli eventi di interruzione e manutenzione dei servizi per i prodotti cloud di Adobe",
  "targetDescription": "Applica le funzioni di sperimentazione, test e automazione basata su machine learning per distribuire i contenuti più adatti a ogni visitatore"
}
