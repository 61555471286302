{
  "accpDescription": "監視主要資產，並執行 Adobe Campaign 的重要管理工作",
  "acsDescription": "管理跨通道的行銷活動，並提升所有媒體環境中的個人化程度",
  "adcloudDescription": "根據預算預測最佳廣告集，並自動執行您的媒體計劃",
  "adminDescription": "檢視所有 Experience Cloud 使用者和詳細資訊，包括存取權、角色，以及最近一次存取的資訊",
  "adminconsoleDescription": "管理 Experience Cloud 使用者、身分和應用程式",
  "analyticsDescription": "收集、整理、分析及報告客戶的一切操作",
  "audiencemanagerDescription": "管理您的所有客戶資料、建立唯一的客群輪廓，並精準鎖定使用者",
  "bizibleDescription": "將收入歸因於您的 B2B 行銷管道、內容資產和促銷活動，以證明和提高 ROI。",
  "cjm-homeDescription": "安排客戶歷程、設計和傳遞訊息，並獲取深入見解",
  "cloudManagerUiDescription": "建置、測試及部署 Adobe 應用程式，並管理您的自訂程式碼部署",
  "ddamDescription": "輕鬆探索和管理數位資產，並與所有共同作業人員共用",
  "experienceManagerUIDescription": "結合數位資產管理和強大的內容管理系統，以提供內容導向的個人化體驗",
  "experiencePlatformUIDescription": "彙總您在各 Adobe 應用程式的所有體驗資料和企業資料",
  "fireflyDescription": "存取您為了延伸 Experience Platform 和 Experience Cloud 產品功能而建置的自訂雲端原生應用程式",
  "implementDescription": "部署及管理 Experience Cloud 產品和服務以及第三方解決方案的標籤",
  "journeysDescription": "使用第三方系統和 Experience Platform 提供的資料，規劃、提供及分析歷程",
  "launchDataCollectionDescription": "將點按資料流資料傳送至網路、行動裝置、邊緣和伺服器上的 Adobe Experience Cloud 和其他目標",
  "launchDescription": "部署及管理 Experience Cloud 產品和服務以及第三方解決方案的標籤",
  "marketoDescription": "自動化和測量每個管道的行銷參與度，以提供最及時、最有效的 B2B 體驗",
  "placesDescription": "啟用具位置感知功能的行動應用程式，透過地理位置功能和服務來採取行動",
  "platformAnalyticsDescription": "使用 Experience Platform 資料來分析各管道的客戶歷程",
  "primetimeDescription": "透過多螢幕電視平台，打造引人入勝的個人化影視體驗",
  "privacyuiDescription": "根據相關的隱私法規，提交、監視及回應客戶的資料存取及刪除請求",
  "softwareDistributionDescription": "輕鬆搜尋、探索及下載 Adobe Experience Manager 及 Adobe Campaign 的套件",
  "systemStatusDescription": "監視 Adobe 雲端產品的服務當機、中斷和維護事件",
  "targetDescription": "利用實驗、測試和機器學習自動化，為每位訪客提供最佳內容"
}
