{
  "accpDescription": "Monitor key assets and perform important administrative tasks for Adobe Campaign",
  "acsDescription": "Manage cross channel campaigns and improve personalization across your full media landscape",
  "adcloudDescription": "Forecast the best mix of ads based on budget and automate execution of your media plan",
  "adminDescription": "View all Experience Cloud users and details including access, role, and last accessed information",
  "adminconsoleDescription": "Manage Experience Cloud users, identity, and applications",
  "analyticsDescription": "Collect, organize, analyze and report on everything your customers do",
  "audiencemanagerDescription": "Manage all of your customer data, build unique audience profiles, and precisely target your users",
  "bizibleDescription": "Attribute revenue to your B2B marketing channels, content assets, and campaigns to prove and improve ROI.",
  "cjm-homeDescription": "Orchestrate customer journeys, design & deliver messages, and gain insights",
  "cloudManagerUiDescription": "Build, test and deploy Adobe applications, and manage your custom code deployments",
  "ddamDescription": "Easily discover, manage and share digital assets with all collaborators",
  "experienceManagerUIDescription": "Deliver personalized, content-led experiences by combining digital asset management with a powerful content management system",
  "experiencePlatformUIDescription": "Aggregate all your experience data across Adobe applications and enterprise data",
  "fireflyDescription": "Access your custom cloud native apps built to extend the functionality of Experience Platform and Experience Cloud products",
  "implementDescription": "Deploy and manage tags for Experience Cloud products and services, and 3rd party solutions",
  "journeysDescription": "Plan, deliver and analyze journeys using data from third party systems and Experience Platform",
  "launchDataCollectionDescription": "Send clickstream data to Adobe Experience Cloud and other destinations across web, mobile, edge, and server",
  "launchDescription": "Deploy and manage tags for Experience Cloud products and services, and 3rd party solutions",
  "marketoDescription": "Automate and measure marketing engagement across every channel to deliver the most timely and effective B2B experiences",
  "placesDescription": "Enable mobile apps with location awareness to take action via geo-location features and services",
  "platformAnalyticsDescription": "Analyze customer journeys across channels using Experience Platform data",
  "primetimeDescription": "Create engaging and personalized TV and film experiences via a multi-screen TV platform",
  "privacyuiDescription": "Submit, monitor and respond to customers’ data access and delete requests under relevant privacy regulations",
  "softwareDistributionDescription": "Easily search for, discover and download of packages for Adobe Experience Manager and Adobe Campaign",
  "systemStatusDescription": "Monitor services outage, disruption and maintenance events for Adobe cloud products",
  "targetDescription": "Use experimentation, testing and machine learning automation to deliver the best content to each visitor"
}
