{
  "accpDescription": "Überwachen Sie wesentliche Assets und führen Sie wichtige administrative Aufgaben für Adobe Campaign durch",
  "acsDescription": "Verwalten Sie kanalübergreifende Kampagnen und verbessern Sie die Personalisierung in all Ihren Medien",
  "adcloudDescription": "Finden Sie die beste Mischung aus Werbeanzeigen unter Berücksichtigung des Budgets und automatisieren Sie die Durchführung Ihres Medienplans",
  "adminDescription": "Lassen Sie sich alle Experience Cloud-Benutzer und Details wie Zugriffe, Rollen und zuletzt aufgerufene Informationen anzeigen",
  "adminconsoleDescription": "Verwalten Sie Experience Cloud-Benutzer, Identitäten und Programme",
  "analyticsDescription": "Erfassen, organisieren und analysieren Sie alles, was Ihre Kunden tun, und erstellen Sie daraus Berichte",
  "audiencemanagerDescription": "Verwalten Sie alle Ihre Kundendaten, erstellen Sie einzigartige Zielgruppenprofile und sprechen Sie Ihre Benutzer gezielt an",
  "bizibleDescription": "Ordnen Sie Ihren B2B-Marketing-Kanälen, Content-Assets und Kampagnen Umsätze zu, um den ROI nachzuweisen und zu verbessern.",
  "cjm-homeDescription": "Customer Journeys koordinieren, Nachrichten gestalten und senden und Einblicke gewinnen",
  "cloudManagerUiDescription": "Erstellen und testen Sie Adobe-Programme, stellen Sie sie bereit und verwalten Sie Ihre benutzerdefinierten Bereitstellungen",
  "ddamDescription": "Finden Sie mühelos digitale Assets, verwalten Sie sie und teilen Sie sie mit Kollegen",
  "experienceManagerUIDescription": "Liefern Sie personalisierte, inhaltsbasierte Erlebnisse durch die Kombination von Digital Asset Management und einem effizienten Content-Management-System",
  "experiencePlatformUIDescription": "Fassen Sie alle Erlebnisdaten aus allen Adobe-Programmen und Unternehmensplattformen zusammen",
  "fireflyDescription": "Greifen Sie auf Ihre benutzerdefinierten Cloud-nativen Apps zu, die die Funktionalität von Experience Platform- und Experience Cloud-Produkten erweitern",
  "implementDescription": "Stellen Sie Tags für Experience Cloud-Produkte und -Services sowie Lösungen Dritter bereit und stellen Sie sie bereit",
  "journeysDescription": "Planen, integrieren und analysieren Sie Journeys mit Daten von Drittsystemen und Experience Platform",
  "launchDataCollectionDescription": "Clickstream-Daten an Adobe Experience Cloud und andere Ziele via Internet, Mobilgeräte, Edge und Server senden",
  "launchDescription": "Stellen Sie Tags für Experience Cloud-Produkte und -Services sowie Lösungen Dritter bereit und stellen Sie sie bereit",
  "marketoDescription": "Automatisieren und messen Sie die Marketing-Interaktionen auf allen Kanälen, um zeitnahe und effektive B2B-Erlebnisse bereitzustellen.",
  "placesDescription": "Aktivieren Sie Standortdaten für Mobile Apps, um Standortfunktionen und -Services nutzen zu können",
  "platformAnalyticsDescription": "Analysieren Sie Customer Journeys auf allen Kanälen mit Daten von Experience Platform",
  "primetimeDescription": "Erstellen Sie attraktive und personalisierte TV- und Filmerlebnisse mithilfe einer aus mehreren Bildschirmen bestehenden TV-Plattform",
  "privacyuiDescription": "Senden, überwachen und beantworten Sie Datenzugriffs- und -löschanfragen von Benutzern gemäß den entsprechenden Datenschutzbestimmungen",
  "softwareDistributionDescription": "Suchen Sie Packages für Adobe Experience Manager und Adobe Campaign und laden Sie sie herunter",
  "systemStatusDescription": "Überwachen Sie Service-Ausfälle, Unterbrechungen und Wartungsarbeiten für Adobe Cloud-Produkte",
  "targetDescription": "Nutzen Sie Experimente, Tests und ML-Automatisierung, um jedem Besucher den besten Inhalt bereitzustellen"
}
