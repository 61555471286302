/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import BrandIcons from 'url:@exc/brand-icons/BrandIcons.svg';

const BRAND_ICONS = [
  'AdminConsole',
  'AdobeExperienceCloud',
  'AdobeExperiencePlatform',
  'AdobeExperienceSubCloud',
  'AppDevelopment',
  'Asset',
  'BoxSearch',
  'CircleAdvertisingCloud',
  'CircleExperienceCloud',
  'CircleExperiencePlatform',
  'CircleJourneyOptimizer',
  'Commerce',
  'ContentProfile',
  'DataAnalytics',
  'DynamicChat',
  'Efficient',
  'EmailGear',
  'EmailSend',
  'ExperienceManager',
  'ExperienceManagerAssetsContentHub',
  'Fusion',
  'Gauge',
  'GraphBarMoney',
  'JourneyOrchestration',
  'Launch',
  'LocationService',
  'MarketoEngage',
  'MixModeler',
  'Primetime',
  'PrimeTimeFunctional',
  'Privacy',
  'ReportMapping',
  'Sapphire',
  'UserAdmin',
  'UserAnalytics',
  'UserHighlight',
  'WorkfrontCircle'
];

export const getBrandIcon = icon => BRAND_ICONS.includes(icon) ? `${BrandIcons}#${icon}` : '';
