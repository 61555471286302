[
  {
    "adobeBrandType": "application",
    "appId": "adcloud",
    "defaultDescription": "Forecast the best mix of ads based on budget and automate execution of your media plan",
    "learnMoreLink": "https://www.adobe.com/advertising-cloud/media-optimizer.html",
    "name": "Advertising Cloud",
    "longName": "Adobe Advertising Cloud",
    "href": {
      "dev": "http://kents-mbp.corp.adobe.com:8084/CMDashboard/?ims=1",
      "qa": "https://enterprise.test.amo.or1.adobe.net/CMDashboard/?ims=1",
      "stage": "https://enterprise.test.amo.or1.adobe.net/CMDashboard/?ims=1",
      "prod": "https://enterprise.efrontier.com/CMDashboard/?ims=1"
    },
    "functionalIconReact": "CircleAdvertisingCloud",
    "serviceCode": ["dma_adlens"],
    "showInQuickAccess": true
  },
  {
    "adobeBrandType": "application",
    "appId": "analytics",
    "defaultDescription": "Collect, organize, analyze and report on everything your customers do",
    "learnMoreLink": "http://www.adobe.com/marketing-cloud/web-analytics.html",
    "name": "Analytics",
    "longName": "Adobe Analytics",
    "functionalIconReact": "DataAnalytics",
    "showInQuickAccess": true
  },
  {
    "adobeBrandType": "application",
    "appId": "audiencemanager",
    "defaultDescription": "Manage all of your customer data, build unique audience profiles, and precisely target your users",
    "learnMoreLink": "https://www.adobe.com/analytics/audience-manager.html",
    "name": "Audience Manager",
    "longName": "Adobe Audience Manager",
    "functionalIconReact": "UserHighlight",
    "showInQuickAccess": true
  },
  {
    "adobeBrandType": "application",
    "appId": "campaignLaunch",
    "defaultDescription": "Manage cross channel campaigns and improve personalization across your full media landscape",
    "learnMoreLink": "https://www.adobe.com/marketing/campaign-management.html",
    "name": "Campaign",
    "longName": "Adobe Campaign",
    "functionalIconReact": "EmailSend",
    "path": "/campaign",
    "showInQuickAccess": true
  },
  {
    "adobeBrandType": "application",
    "appId": "experienceManagerUI",
    "defaultDescription": "Deliver personalized, content-led experiences by combining digital asset management with a powerful content management system",
    "learnMoreLink": "https://www.adobe.com/marketing/experience-manager.html",
    "name": "Experience Manager",
    "longName": "Adobe Experience Manager",
    "functionalIconReact": "ExperienceManager",
    "showInQuickAccess": true
  },
  {
    "adobeBrandType": "application",
    "appId": "commerceInstanceDiscovery",
    "name": "Commerce",
    "longName": "Adobe Commerce",
    "functionalIconReact": "Commerce",
    "showInQuickAccess": true
  },
  {
    "adobeBrandType": "application",
    "appId": "adobemixmodeler",
    "defaultDescription": "",
    "name": "Mix Modeler",
    "learnMoreLink": "",
    "longName": "Adobe Mix Modeler",
    "path": "/mix-modeler",
    "functionalIconReact": "MixModeler",
    "showInSwitcher": true,
    "showInQuickAccess": true
  },
  {
    "adobeBrandType": "application",
    "appId": "adobepassunifiedshellconsoleclient",
    "name": "Pass Authentication",
    "longName": "Adobe Pass Authentication",
    "path": "/pass/authentication",
    "serviceCode": ["dma_primetime"],
    "functionalIconReact": "PrimeTimeFunctional",
    "showInQuickAccess": true
  },
  {
    "adobeBrandType": "application",
    "appId": "target",
    "defaultDescription": "Use experimentation, testing and machine learning automation to deliver the best content to each visitor",
    "learnMoreLink": "https://www.adobe.com/marketing/target.html",
    "name": "Target",
    "longName": "Adobe Target",
    "path": "/target",
    "functionalIconReact": "ContentProfile",
    "showInQuickAccess": true
  },
  {
    "adobeBrandType": "application",
    "appId": "ContentHub",
    "name": "Experience Manager Assets Content Hub",
    "longName": "Adobe Experience Manager Assets Content Hub",
    "path": "/assets/contenthub",
    "showInSwitcher": true,
    "showInQuickAccess": true,
    "functionalIconReact": "ExperienceManagerAssetsContentHub"
  },
  {
    "adobeBrandType": "application",
    "appId": "GenStudio",
    "name": "GenStudio",
    "longName": "Adobe GenStudio",
    "path": "/genstudio",
    "functionalIconReact": "CircleAdvertisingCloud",
    "showInSwitcher": true,
    "showInQuickAccess": true
  }
]
